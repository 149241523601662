import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useDebouncedCallback } from "use-debounce";
import { BaseIcon } from "@/src/component/base/BaseIcon";
import { useApp } from "@/src/component/provider";
import { SearchLogic } from "@/src/model";
import { NumberHelper } from "@/src/util";
import styles from "./QuickFilters.module.scss";

enum QuickFilterValues {
  CAPACITY = "capacityV2",
  EVENT_TYPE = "eventType",
  REGIONS = "regions",
}

function QuickFilters() {
  const app = useApp();
  const [guestCount, setGuestCount] = useState("");

  const eventTypeOptions = useMemo(() => SearchLogic.FILTERS.eventType.options || [], []);
  const regionOptions = useMemo(() => SearchLogic.FILTERS.regions.options || [], []);

  function setSingleChoice(changeTo: string, dataKey: string) {
    const currentFilter = app.searchFilters?.getFilterData(dataKey) ?? "";
    const newValue = changeTo === currentFilter ? "" : changeTo;
    app.searchFilters?.setFilterData(dataKey, newValue);
    SearchLogic.trackFilterUsed(changeTo, dataKey, "QuickFilters.tsx");
  }

  const debounceGuestValue = useDebouncedCallback((guestValue: string) => {
    app.searchFilters?.setFilterData(QuickFilterValues.CAPACITY, guestValue);
    SearchLogic.trackFilterUsed(guestValue, QuickFilterValues.CAPACITY, "QuickFilters.tsx");
  }, 500);

  const handleGuestCountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const guestValue = NumberHelper.clearInputValue(event.target.value);
    setGuestCount(guestValue);
    debounceGuestValue(guestValue);
  };

  useEffect(() => {
    const guestFromUrl = app.searchFilters?.getFilterData(QuickFilterValues.CAPACITY);
    if (guestFromUrl) {
      setGuestCount(guestFromUrl as string);
    }
  }, []);

  return (
    <Box className={styles.quickFiltersContainer}>
      <Autocomplete
        disablePortal
        options={eventTypeOptions}
        getOptionLabel={(option) => option.label}
        className={styles.eventSelect}
        value={eventTypeOptions.find(
          (el) => el.value === app.searchFilters?.getFilterData(QuickFilterValues.EVENT_TYPE),
        )}
        onChange={(e, val) => setSingleChoice(val?.value || "", QuickFilterValues.EVENT_TYPE)}
        popupIcon={null}
        renderInput={(params) => (
          <TextField
            placeholder="Event type"
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <BaseIcon iconFileName="groupShareIcon" iconAlt="event type" />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <TextField
        placeholder="Guest count"
        className={styles.guestCount}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BaseIcon iconFileName="profileGreyIcon" iconAlt="guest count" />
            </InputAdornment>
          ),
        }}
        onChange={handleGuestCountChange}
        value={NumberHelper.formatFormInputValue(guestCount)}
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      />
      <Select
        id="city-select"
        className={styles.citySelect}
        value={app.searchFilters?.getFilterData(QuickFilterValues.REGIONS) ?? ""}
        onChange={(e) => setSingleChoice(e.target.value, QuickFilterValues.REGIONS)}
        displayEmpty
        renderValue={(selected) => {
          return selected
            ? regionOptions.find((region) => region.value === selected)?.label
            : "Location";
        }}
        input={
          <OutlinedInput
            startAdornment={
              <InputAdornment position="start">
                <BaseIcon iconFileName="locationPinOutlinedIcon" iconAlt="location selection" />
              </InputAdornment>
            }
          />
        }
      >
        <MenuItem value="">Any Location</MenuItem>
        {regionOptions.map((region) => (
          <MenuItem key={region.value} value={region.value}>
            {region.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

export default QuickFilters;
